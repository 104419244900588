import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyChPWMIz6mQ9coQFYVxiGDct7IV9aCtiSk",
  authDomain: "eatery-68b82.firebaseapp.com",
  projectId: "eatery-68b82",
  storageBucket: "eatery-68b82.appspot.com",
  messagingSenderId: "615180293622",
  appId: "1:615180293622:web:b3e9ae8c468f7575e5e3d7",
  measurementId: "G-4FBBE9F5Z3",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage };
