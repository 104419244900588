import React from "react";
import { Link } from "react-router-dom";

function Header() {
  const menus = [
    {
      title: "Check Out",
      path: "/checkout",
    },
    {
      title: "Request Product",
      path: "/request",
    },
  ];

  return (
    <div>
      <div className="w-full px-5 h-12 bg-slate-800 flex items-center justify-between">
        <div>
          {menus.map((menu, i) => (
            <Link
              key={i}
              to={menu.path}
              className="px-2 text-white cursor-pointer"
            >
              {menu.title}
            </Link>
          ))}
        </div>
        <div className="flex flex-row">
          <span className="text-white font-bold text-4xl pr-2">
            {new Date().getDate() <= 9
              ? "0" + new Date().getDate()
              : new Date().getDate()}
          </span>
          <div>
            <h1 className="text-white font-bold text-sm ">
              {new Date()
                .toLocaleString("en", { month: "short" })
                .toUpperCase()}
            </h1>
            <h1 className="text-white font-bold text-sm ">
              {new Date().getFullYear()}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
